<template>
    <div class="min-h-0 flex flex-col flex-grow">
        <component 
            v-if="!isError && isStoreLoaded"
            :is="checkError" 
            storeName="workgroups"
            :pageConfig="pageConfig" />
        <a-spin 
            v-if="!isError && !isStoreLoaded" 
            class="py-4" />
    </div>
</template>

<script>
import pageMeta from '@/mixins/pageMeta'
import { hasInInjectInit } from '@/utils/checkStore.js'

export default {
    mixins: [pageMeta],

    computed: {
        pageConfig() {
            return this.$route.meta?.pageConfig || null
        },
        checkError() {
            if(!this.isError) 
                return () => import('@apps/vue2GroupsAndProjectsComponent/Groups')
            return () => import('@/components/PageStoreError.vue')
        },
        isError() {
            return !hasInInjectInit('vue2GroupsAndProjectsComponent')
        },
        isStoreLoaded() {
            return this.$store.state.connectedModules.includes('workgroups')
        }
    },
}
</script>

